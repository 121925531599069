import React from "react"
import styled from "styled-components"
import Map from "./Map.jsx"
import {useTranslation} from "react-i18next"
const Footer = () => {

  const location = {
    address: "Via Gennaro Capriglione, 123, 84010 Praiano SA",
    lat: 40.61324812422141,
    lng: 14.522257454092808,
  }

  const {t} = useTranslation()

  return (
    <footer>
      <StyledInfoContainer>
        <div className="infoDiv">
          <h2>{t('footer.info.altre')}</h2>
          <p><a href="https://www.ilcoralloamalficoast.com" target="_blank" rel="noreferrer">Il Corallo</a></p>
        </div>
        <div className="infoDiv">
          <h2>{t('footer.info.title')}</h2>
						<p>
							{t("footer.info.indirizzo")}:<br />
							<b>Via Gennaro Capriglione, 123 Praiano (SA)</b>
						</p>
						<p>
							{t("footer.info.email")}:<br />
              <a href="mailto:info@stellamarinaamalficoast.com">info@stellamarinaamalficoast.com</a>
						</p>
						<p>
							{t("footer.info.telefono")}:<br />
              <a href="tel:00393396029485">+39 339 6029 485</a><br />
              <a href="tel:00393089813069">+39 089 813 069</a>
						</p>
        </div>
        <StyledMapContainer>
          <Map location={location} />
        </StyledMapContainer>
      </StyledInfoContainer>
      <StyledCopyrightContainer className="copyright">
        <p>Copyright Tramonto D'Oro 2021 | P.IVA 05355710657</p>
        <p>Made with ❤️ by Andrea Ercolino</p>
      </StyledCopyrightContainer>
    </footer>
  )
}

const StyledInfoContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  background-color: white;
  gap: 19px;
  height: 100%;
  .infoDiv {
    min-height: 400px;
    padding-top: 1rem;
    padding-left: 1rem; 

    @media(max-width: 869px){
      min-height: unset;
    };

    p {
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }

  };

  h2 {
    color: var(--color-red);
    margin-bottom: 1rem;
  };

  @media (max-width: 869px){
    grid-template-columns: 1fr;
    text-align: center;
  }
`

const StyledCopyrightContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-blu);
  color: white;
`
const StyledMapContainer = styled.div`
  @media(max-width: 869px) {
    height: 400px;
  }
`;


export default Footer;
