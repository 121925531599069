
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Dropdown = () => {
	const [open, setOpen] = useState(false);
	const toggle = () => setOpen(!open);
	const history = useHistory();
  const {t} = useTranslation();

	return (
		<StyledDropdown onClick={toggle}>
			<StyledClickable>{t('navbar.camere')}</StyledClickable>
			{open ? (
				<StyledDropdownItems>
					<div>
						<button
							onClick={() => {
								history.push(`/camere/${"vistaMare"}#header`);
							}}
						>
							{t('navbar.camera.vistaMare')}
						</button>
					</div>
					<div>
						<button
							onClick={() => {
								history.push(`/camere/${"vistaGiardino"}#header`);
							}}
						>
							{t('navbar.camera.vistaGiardino')}
						</button>
					</div>
				</StyledDropdownItems>
			) : (
				<></>
			)}
		</StyledDropdown>
	);
};

const StyledDropdown = styled.div`
  position: relative;
`;

const StyledClickable = styled.p`
  cursor: pointer;
  font-weight: bold;
`;

const StyledDropdownItems = styled.div`
  position: absolute;
  min-width: 150px;
  top: 2.4rem;
  right: 50%;
  transform: translateX(50%);
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 1rem 1rem;

  div {
    margin-bottom: .6rem;
    &:last-child {
     margin-bottom: 0rem;
    }
  }

  button {
    border: none;
    padding: none;
    background-color: transparent;
    color: var(--color-primary);
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Dropdown;
