import React from "react"
import styled from "styled-components"
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const Map = ({location}) => {
  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyBEn-s8Fj4-AB9wQRwq6Xf2P_SknxdNGWI",
        }}
        defaultCenter={location}
        defaultZoom={17}
      >
        <LocationPin 
          lat={location.lat}
          lng={location.lng}
          text={location.address}
        />
        </GoogleMapReact>
    </>
  )
}

const LocationPin = ({text}) => {
  return (
  <StyledMarkerContainer className="marker">
    <StyledIcon icon={locationIcon} className="icona" />
    <StyledAddressParagraph className="indirizzo">{text}</StyledAddressParagraph>
  </StyledMarkerContainer>)
} 

const StyledMarkerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 180px;
  color: var(--color-red);
`

const StyledIcon = styled(Icon)`
  font-size: 7rem;
`

const StyledAddressParagraph = styled.p`
  font-size: 1rem;
`


export default Map;
