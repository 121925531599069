import React from "react";
import {Link, useHistory} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from 'react-i18next';

const SideDrawer = ({show, drawClickHandler}) => {
	let classes = "sideDrawer";
	if (show) {
		classes = "sideDrawer open";
	}
	const {t, i18n} = useTranslation();
	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	}


	const history = useHistory();
	return (
		<nav className={classes}>
			<div>
				<StyledSVG
					onClick={drawClickHandler}
					height='50'
					width='50'
					viewBox='0 0 137 150'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path d='M135.073 113.965C137.642 116.777 137.642 121.348 135.073 124.16L113.4 147.891C110.831 150.703 106.657 150.703 104.088 147.891L68.5 108.574L32.9121 147.891C30.3434 150.703 26.1691 150.703 23.6004 147.891L1.92656 124.16C-0.642188 121.348 -0.642188 116.777 1.92656 113.965L37.8355 75L1.92656 36.0352C-0.642188 33.2227 -0.642188 28.6523 1.92656 25.8398L23.6004 2.10938C26.1691 -0.703125 30.3434 -0.703125 32.9121 2.10938L68.5 41.4258L104.088 2.10938C106.657 -0.703125 110.831 -0.703125 113.4 2.10938L135.073 25.8398C137.642 28.6523 137.642 33.2227 135.073 36.0352L99.1644 75L135.073 113.965Z' />
				</StyledSVG>
			</div>
			<StyledUl>
				<li >
					<Link onClick={drawClickHandler} to='/'>{t('navbar.home')}</Link>
				</li>
				<li>
					<button
						onClick={() => {
              history.push(`/camere/${"vistaMare"}`);
              drawClickHandler();
						}}
					>
						{t('navbar.camera.vistaMare')}
					</button>
				</li>
				<li>
					<button
						onClick={() => {
							history.push(`/camere/${"vistaGiardino"}`);
              drawClickHandler();
						}}
					>
						{t('navbar.camera.vistaGiardino')}
					</button>
				</li>
				<li>
					<Link onClick={drawClickHandler} to='/ViverePraiano'>{t('navbar.viverePraiano')}</Link>
				</li>
				<li>
					<Link onClick={drawClickHandler} to='/info'>{t('navbar.daSapere')}</Link>
				</li>
				<StyledFlagContainer>
					<li>
						<button onClick={() => changeLanguage('en')} >
							<img
								src='/imgs/uk.svg'
								width='60px'
								alt='change language in english'
							/>
						</button>
					</li>
					<li>
						<button onClick={() => changeLanguage('it')} >
							<img
								src='/imgs/it.png'
								width='60px'
								alt='cambia lingua in italiano'
							/>
						</button>
					</li>
				</StyledFlagContainer>
			</StyledUl>
		</nav >
	);
};

const StyledUl = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-align: center;

  li {
    margin: 0.8rem 3rem;
    font-size: 1.5rem;

    button {
      border: none;
      background-color: transparent;
      margin: 0;
      font-size: inherit;
      color: inherit;

      &:hover {
        text-decoration: underline;
        color: var(--color-blu)
      }
    }
  }
`;

const StyledSVG = styled.svg`
  position: absolute;
  top: 40px;
  left: 40px;
  color: var(--color-primary);

  path {
    fill: var(--color-primary);
  }
`;

const StyledFlagContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
`;

export default SideDrawer;
