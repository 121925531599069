import React, { useState } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Camera from "./pages/Camera";
import ViverePraiano from "./pages/ViverePraiano.jsx";
import DaSapere from "./pages/DaSapere.jsx";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer.jsx";
import SideDrawer from "./components/SideDrawer.jsx";
import Besaferate from "./pages/Besaferate";

function App() {
	const [sideDrawOpen, setSideDrawOpen] = useState(false);

	const drawerToggleClickHandler = () => {
		setSideDrawOpen(!sideDrawOpen);
	};

	return (
		<main className="App">
			<SimpleReactLightbox>
				<Router>
					<SideDrawer
						show={sideDrawOpen}
						drawClickHandler={drawerToggleClickHandler}
					/>
					<Navbar drawClickHandler={drawerToggleClickHandler} />
					<Switch>
						<Route exact path="/">
							<Home />
						</Route>
						<Route
							path="/camere/:id"
							render={(props) => {
								return <Camera {...props} />;
							}}
						/>
						<Route path="/viverePraiano">
							<ViverePraiano />
						</Route>
						<Route path="/info">
							<DaSapere />
						</Route>
						<Route path="/besaferate">
							<Besaferate />
						</Route>
					</Switch>
					<Footer />
				</Router>
			</SimpleReactLightbox>
		</main>
	);
}

export default App;
