import React, {useEffect} from "react"
import styled from "styled-components"
import {useTranslation} from "react-i18next"
const ViverePraiano = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const {t} = useTranslation();
  const viverePraiano = [
    {
      title: t('viverePraiano.daNonPerdere.foodAndBeverage.title'),
      strutture: [
        {
          title: t('viverePraiano.daNonPerdere.foodAndBeverage.cena.title'),
          body: t('viverePraiano.daNonPerdere.foodAndBeverage.cena.body')
        }, 
        {
          title: t('viverePraiano.daNonPerdere.foodAndBeverage.rooftop.title'),
          body: t('viverePraiano.daNonPerdere.foodAndBeverage.rooftop.body')
        },
        {
          title: t('viverePraiano.daNonPerdere.foodAndBeverage.mirante.title'),
          body: t('viverePraiano.daNonPerdere.foodAndBeverage.mirante.body')
        },
      ]
    },
    {
      title: t('viverePraiano.daNonPerdere.spiagge.title'),
      strutture: [
        {
          title: t('viverePraiano.daNonPerdere.spiagge.gavitella.title'),
          body: t('viverePraiano.daNonPerdere.spiagge.gavitella.body')
        }, 
      ]
    },
    {
      title: t('viverePraiano.daNonPerdere.shopping.title'),
      strutture: [
        {
          title: t('viverePraiano.daNonPerdere.shopping.bacheca.title'),
          body: t('viverePraiano.daNonPerdere.shopping.bacheca.body')
        },
        {
          title: t('viverePraiano.daNonPerdere.shopping.damario.title'),
          body: t('viverePraiano.daNonPerdere.shopping.damario.body')
        },
        {
          title: t('viverePraiano.daNonPerdere.shopping.sandulli.title'),
          body: t('viverePraiano.daNonPerdere.shopping.sandulli.body')
        },
        {
          title: t('viverePraiano.daNonPerdere.shopping.rosalinda.title'),
          body: t('viverePraiano.daNonPerdere.shopping.rosalinda.body')
        },
        {
          title: t('viverePraiano.daNonPerdere.shopping.limoncello.title'),
          body: t('viverePraiano.daNonPerdere.shopping.limoncello.body')
        }, 
      ]
    },
    {
      title: t('viverePraiano.daNonPerdere.escursioni.title'),
      strutture: [
        {
          title: t('viverePraiano.daNonPerdere.escursioni.capri.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.capri.body')
        }, 
        {
          title: t('viverePraiano.daNonPerdere.escursioni.positano.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.positano.body')
        }, 
        {
          title: t('viverePraiano.daNonPerdere.escursioni.amalfi.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.amalfi.body')
        }, 
        {
          title: t('viverePraiano.daNonPerdere.escursioni.ravello.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.ravello.body')
        }, 
        {
          title: t('viverePraiano.daNonPerdere.escursioni.naturarte.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.naturarte.body')
        }, 
        {
          title: t('viverePraiano.daNonPerdere.escursioni.dei.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.dei.body')
        },
        {
          title: t('viverePraiano.daNonPerdere.escursioni.scavi.title'),
          body: t('viverePraiano.daNonPerdere.escursioni.scavi.body')
        },
      ]
    },
        
  ]


  return (
      <StyledSection id="header">
      <div className="container">
        <StyledContainer>
          <StyledMainDivs>
            <StyledImg src="/imgs/panorama_9.JPG" />
            <StyledInfoContainer>
            <h1>{t('viverePraiano.title')}</h1>
            <p>{t('viverePraiano.info')}</p>
            </StyledInfoContainer>
          </StyledMainDivs>
          <StyledMainDivs>
          <div className="infoSection">
            {viverePraiano.map((attivita, index) => {
              return (
                <React.Fragment key={index}>
                  <h2>{attivita.title}</h2>
                  <StyledInfoBodyContainer>
                  {attivita.strutture.map((struttura, index) => {
                    return (<div key={index} className="infoBody">
                      <h3>{struttura.title}</h3>
                      <p>{struttura.body}</p>
                    </div>)
                  })}
                  </StyledInfoBodyContainer>
                </React.Fragment>
              )
            })}
          </div>
        </StyledMainDivs>
      </StyledContainer>
    </div>
  </StyledSection>)}


const StyledSection = styled.section`
  padding: 2rem;
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.61) -5.45%, #44C3E5 59.91%, #021979 100%);
  
  @media (max-width: 1200px){
    padding-top: 10rem;
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(5px, 100px) 2fr;

  @media (max-width: 1200px){
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
  }

`

const StyledMainDivs = styled.div`
  border-radius: var(--border-radius);
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-bottom: 2rem;

  &:nth-child(2) {
    grid-column: 3/4;
    padding: 2rem;
    height: calc(768px - 2rem);
    overflow-y: scroll; 
    h2 {
      font-size: 2.66rem;
      color: rgba(0, 0, 0, .6);
      margin-bottom: 1rem;
    };

    @media (max-width: 1200px){
      grid-column: 1/2;
   };
  }
`

const StyledInfoContainer = styled.div`
  padding: 2rem 2rem 2rem 2rem; 
  text-align: center;
  h1 {
    font-size: 2.66rem;
    margin-bottom: 1rem;
    color: var(--color-red)
  };

  p {
    margin-bottom: 2rem;
  }

`

const StyledInfoBodyContainer = styled.div`
  display: grid; 
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 2.5rem; 
  div {
    h3 {
      font-size: 1.33rem;
      font-weight: bold;
      margin-bottom: .5rem;
    }
  }

  &:last-child {
    margin-bottom: 2rem;
  };

  @media (max-width: 800px) {
    
    grid-template-columns: 1fr;
  }

`

const StyledImg = styled.img`
  border-radius: var(--border-radius);
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
`




export default ViverePraiano;
