import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []) 
  const history = useHistory();
  const {t} = useTranslation();
  const camere = {
    vistaMare: [
		{
			img: "/imgs/17.jpg",
		},
		{
			img: "/imgs/22.jpg",
		},
		{
			img: "/imgs/24.jpg",
		}],
    vistaGiardino: [{
			img: "/imgs/29.jpg",
		},
		{
			img: "/imgs/31.jpg",
		},
		{
			img: "/imgs/35.jpg",
		}],
	}

	return (
		<>
			<StyledJumbotronSection id="header">
				<StyledJumbotronCard>
					<div>
						<h1>Casa Stella Marina</h1>
						<p>{t('home.hero_subtitle')}</p>
					</div>
					<StyledButtonContainer>
						<StyledCatLink href="https://booking.ericsoft.com/BookingEngine/Book?idh=FB9C455E247A8019">{t('bottoni.prenotaOra')}</StyledCatLink>
						<StyledCatBorderA href="#camere">{t('bottoni.scopri')}</StyledCatBorderA>
					</StyledButtonContainer>
				</StyledJumbotronCard>
			</StyledJumbotronSection>
			<section className="info">
				<StyledGridContainer className="gridContainer">
					<img src="/imgs/panorama_2.JPG" alt="natura" />
          <div>
              <div>
						  <h2>{t('home.atmosfera.title')}</h2>
              <p>{t('home.atmosfera.body')}</p>
              </div>
						<StyledCatBorder className="gridItemLink" to="/viverePraiano#header">{t('bottoni.scopri')}</StyledCatBorder>
					</div>
					<img className="gridImg" src="/imgs/panorama_6.JPG" alt="natura" />
          <div className="gridItem">
            <div>
						  <h2>{t('home.posizione.title')}</h2>
              <p>{t('home.posizione.body')}</p>
            </div>
						<StyledCatBorder className="gridItemLink" to="/viverePraiano#header">{t('bottoni.scopri')}</StyledCatBorder>
					</div>
				</StyledGridContainer>
			</section>
			<StyledCamereSection id="camere">
        <h2>{t('home.camere.title')}</h2>
        <h3 className="subTitle">{t('home.atmosfera.vistaMare')}</h3>
				<div className="container">
					<StyledCamereGrid className="camereGrid">
						{camere.vistaMare.map((camera, index) => {
							return (
                <StyledCameraGridItem key={index} className="camereGridItem">
                  <button onClick={() => history.push('/camere/vistaMare#header')}>
                    <img src={camera.img} alt={camera.text} />
                    </button >
									<h3 onClick={() => history.push('/camere/vistaMare#header')}>{t('bottoni.scopri')}</h3>
								</StyledCameraGridItem>
							)
            })}
					</StyledCamereGrid>
          <h3 className="subTitle">{t('home.atmosfera.vistaGiardino')}</h3>
					<StyledCamereGrid className="camereGrid">
            {camere.vistaGiardino.map((camera, index) => {
							return (
                <StyledCameraGridItem key={index} className="camereGridItem">
                  <button onClick={() => history.push('/camere/vistaGiardino#header')}>
                    <img src={camera.img} alt={camera.text} />
                    </button >
									<h3 onClick={() => history.push('/camere/vistaGiardino#header')}>{t('bottoni.scopri')}</h3>
								</StyledCameraGridItem>
							)
						})}
					</StyledCamereGrid>
				</div>
			</StyledCamereSection>
		</>
	);
}

const StyledJumbotronSection = styled.section`
		background-image: url("/imgs/panorama_1.JPG");
  background-attachment: fixed;
  background-repeat: no-repeat;
		display: grid;
		align-items: center;
		justify-content: center;
		min-height: 100vh;
  background-position: bottom;
  background-size: cover;
	
`

const StyledJumbotronCard = styled.div`
		background: var(--color-blu);
		border-radius: var(--border-radius);
		padding: 2rem;
		text-align: center;
		color: white;

		width: 500px;
		height: 190px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		h1 {
				margin-bottom: 1rem;
				font-size: 3rem;
		};

		p {
				font-weight: 700;
				margin-bottom: .9rem;
				font-size: 1rem;
				text-transform: uppercase;
  };

  @media (max-width: 869px){
    width: auto;
		height: auto;
  }
`

const StyledButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
  font-weight: bold;
  width: 75%;
  margin: 0 auto; 
  @media (max-width: 869px){
    flex-direction: column;
    gap: 10px;
  }
`

const StyledCatLink = styled.a`
	background-color: var(--color-light);
	color: white;
  padding: .5rem 1rem;
	border-radius: var(--border-radius);
  text-decoration: none;
  display: block;
  transition: all .3s ease;

  &:hover {
    text-decoration: none;
    color: white;
    transform: scale(1.1);
  }
`

const StyledCatBorder = styled(Link)`
	border-radius: var(--border-radius);
	border: 2px solid var(--color-light);
	padding: .5rem 1rem;
  border-radius: var(--border-radius);	
  color: white;
	text-decoration: none;
`
const StyledCatBorderA = styled.a`
	border-radius: var(--border-radius);
	border: 2px solid var(--color-light);
	padding: .5rem 1rem;
  border-radius: var(--border-radius);	
  color: white;
  text-decoration: none;
  transition: all .3s ease;
  &:hover {
    text-decoration: none;
    color:white;
    background-color: var(--color-light)

  }
`

const StyledGridContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
	img {
			grid-column: 1/2;
			grid-row: 1/2;

    @media(max-width: 1100px) {
      grid-row: unset;
    }
	};

	.gridImg {
			grid-column: 2/3;
    grid-row: 2/3;
    @media(max-width: 1100px) {
      grid-column: 1/2;
      grid-row: unset;
    }
	};

	div {
			grid-column: 2/3;
    grid-row: 1/2;

    @media(max-width: 1100px) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
	};

	.gridItem  {
			grid-column: 1/2;
    grid-row: 2/3; 
      @media(max-width: 1100px) {
      grid-column: 1/2;
      grid-row: 4/5;
    }
	};

	div,
 .gridItem {
    display: grid;
    grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
    align-items: center;
		justify-content: center;
    text-align: center;
    
    div {
      display: block;
      grid-row: 1/2;
      grid-column: 1/2;
    };

		h2 {
      color: var(--color-red);
      font-size: 2.3rem;
      margin-bottom: 1rem;
    };

		p {
		  width: 95%;
			margin: 0 auto;
    };

    .gridItemLink {
      grid-row: 2/3;
			color: var(--color-red);
      transition: all  .3s ease;
      border-color: var(--color-red);
			width: 35%;
      margin: 0 auto;
      font-size: 1.5rem;
			
      &:hover {
				background-color: var(--color-red);
        color: white;
        text-decoration: none;
			}
    } ;
   
    };	 

    @media(max-width: 1100px) {
      grid-template-columns: 1fr;
      gird-template-rows: 1fr 1fr 1fr 1fr;
    }
`

const StyledCamereSection = styled.section`
	background-image: linear-gradient(0deg,rgba(15,96,178,0.7),rgba(15,96,178,0.52)),url("/imgs/panorama_4.JPG");
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	padding-top: 3rem;
	padding-bottom: 3rem;
	h2 {
    margin-bottom: 3rem;
    font-size: 2.66rem;
		color: white;
  };

  .subTitle {
    margin-bottom: 2rem;
    font-size: 2rem;
    color: white;
  }
`

const StyledCamereGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr; 
	text-align: center;
  gap: 40px;
  margin-bottom: 3rem;

  @media(max-width: 1100px) {
	  grid-template-columns: 1fr 1fr;
  };
  @media(max-width: 869px) {
	  grid-template-columns: 1fr;
  }
`

const StyledCameraGridItem = styled.div`
	color: white;
    
  button {
    border: none; 
    background-color: transparent;
  }

	h3 {
    cursor: pointer;
    padding: .2rem .5rem;
    border-radius: var(--border-radius);
    border: 2px solid var(--color-red);
    transition: all .3s ease;
    margin: 0 auto;
    width: 35%;
    margin-top: 1rem;
    
    &:hover {
      background: var(--color-red);
    }
	};

	img {
		cursor: pointer;
		transition: transform .3s ease;
		z-index: 0;
    border-radius: var(--border-radius);
		
    &:hover {
		  transform: scale(1.1)
			}
	}
`

export default Home;
