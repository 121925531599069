import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { SRLWrapper } from "simple-react-lightbox";

const Camera = ({ match }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const { t } = useTranslation();

	const camere = [
		{
			slug: "vistaMare",
			title: t("home.atmosfera.vistaMare"),
			text: t("camere.vistaMare"),
			headerColor: "#354467",
			buttonBackground: "#A8B0CA",
			buttonText: "#003974",
			infoBackground: "#A8B0CA",
			accentColor: "#003A76",
			textColor: "black",

			foto: [
				// "/imgs/19.jpg",
				"/imgs/14.jpg",
				"/imgs/15.jpg",
				"/imgs/16.jpg",
				"/imgs/17.jpg",
				"/imgs/18.jpg",
				"/imgs/20.jpg",
				"/imgs/21.jpg",
				"/imgs/22.jpg",
				"/imgs/23.jpg",
				"/imgs/13.jpg",
				"/imgs/24.jpg",
				"/imgs/25.jpg",
				"/imgs/26.jpg",
				"/imgs/panorama_9.JPG",
				"/imgs/panorama_7.JPG",
			],
		},

		{
			slug: "vistaGiardino",
			title: t("home.atmosfera.vistaGiardino"),
			text: t("camere.vistaGiardino"),
			headerColor: "#264582",
			buttonBackground: "white",
			buttonText: "#264582",
			infoBackground: "#C2D1E3",
			accentColor: "#002A86",
			textColor: "black",

			foto: [
				"/imgs/30.jpg",
				"/imgs/29.jpg",
				"/imgs/27.jpg",
				"/imgs/28.jpg",
				"/imgs/31.jpg",
				"/imgs/32.jpg",
				"/imgs/33.jpg",
				"/imgs/34.jpg",
				"/imgs/35.jpg",
				"/imgs/37.jpg",
				"/imgs/38.jpg",
				"/imgs/36.jpg",
			],
		},
	];

	const camera = camere.filter((c) => c.slug === match.params.id)[0];
	return (
		<main id="header">
			<StyledHeaderSection image={camera.foto[0]}>
				<StyledHeaderCard
					id="header"
					buttonColor={camera.buttonBackground}
					color={camera.headerColor}
					buttonText={camera.buttonText}>
					<h1>{camera.title}</h1>
					<a href="https://booking.ericsoft.com/BookingEngine/Book?idh=FB9C455E247A8019">
						{t("bottoni.prenotaOra")}
					</a>
				</StyledHeaderCard>
			</StyledHeaderSection>
			<StyledInfoSection
				accentColor={camera.accentColor}
				textColor={camera.textColor}
				color={camera.infoBackground}
				className="infoSection">
				<StyledFlexContainer>
					<StyledInformationContainer className="paddedDiv">
						<h3>{t("camere.infoTitle")}</h3>
						{/* <p>{t("camere.info")}</p> */}
						<p>{camera.text}</p>
					</StyledInformationContainer>
					<StyledFlexUlContainer
						color={camera.accentColor}
						className="paddedDiv">
						<ul>
							<li>{t("camere.servizi.TV")}</li>
							<li>{t("camere.servizi.AC")}</li>
							<li>{t("camere.servizi.WIFI")}</li>
							<li>{t("camere.servizi.CORTESIA")}</li>
							<li>{t("camere.servizi.PANTOFOLE")}</li>
						</ul>
						<div />
						<ul>
							<li>{t("camere.servizi.PHON")}</li>
							<li>{t("camere.servizi.SAFE")}</li>
							<li>{t("camere.servizi.BOLLITORE")}</li>
							<li>{t("camere.servizi.MINIBAR")}</li>
							<li>{t("camere.servizi.TERRAZZINO")}</li>
						</ul>
					</StyledFlexUlContainer>
				</StyledFlexContainer>
			</StyledInfoSection>
			<StyledGallerySection
				accentColor={camera.accentColor}
				textColor={camera.textColor}
				color={camera.infoBackground}>
				<div className="container">
					<h2>{t("camere.gallery")}</h2>
					<SRLWrapper>
						<StyledGalleryGrid>
							{camera.foto.map((foto, index) => {
								return <img key={index} src={foto} alt={camera.title} />;
							})}
						</StyledGalleryGrid>
					</SRLWrapper>
				</div>
			</StyledGallerySection>
		</main>
	);
};

const StyledHeaderSection = styled.section`
	min-height: 100vh;
	display: grid;
	align-items: center;
	justify-content: center;
	background-image: url(${(props) => props.image});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
`;

const StyledHeaderCard = styled.div`
	border-radius: var(--border-radius);
	color: white;
	width: 500px;
	margin: 0 auto;
	padding: 3rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	background: ${(props) => props.color};

	h1 {
		font-size: 3rem;
		text-align: center;
		margin-bottom: 1rem;
		color: ${(props) => (props.color === "white" ? props.buttonText : "white")};
	}

	a {
		margin: 0 auto;
		padding: 0.5rem 1rem;
		text-decoration: none;
		color: ${(props) => props.buttonText};
		border-radius: var(--border-radius);
		background-color: ${(props) => props.buttonColor};
		font-weight: bold;
	}

	@media (max-width: 869px) {
		width: auto;
	}
`;

const StyledInfoSection = styled.section`
	background-color: ${(props) => props.color};
	padding-top: 2rem;
	padding-bottom: 2rem;
	color: ${(props) => props.textColor};

	h3 {
		font-size: 1.8rem;
		margin-bottom: 1rem;
		color: ${(props) => props.accentColor};
	}
`;

const StyledFlexContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.paddedDiv {
		padding: 2rem;
	}

	@media (max-width: 869px) {
		flex-direction: column;
		text-align: center;
		gap: 18px;
		.paddedDiv {
			padding: 0;
		}
	}
`;
const StyledFlexUlContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	min-width: 400px;

	div {
		height: 150px;
		width: 5px;
		background-color: ${(props) => props.color};
		margin: 0 1rem 0 1rem;
	}

	ul {
		list-style: none;
		text-align: right;
		width: 50%;
		&:last-child {
			text-align: left;
		}
	}

	@media (max-width: 869px) {
		justify-content: space-between;
	}
`;

const StyledInformationContainer = styled.div`
	max-width: 500px;
`;

const StyledGallerySection = styled.section`
	background: ${(props) => props.color};
	padding-top: 1rem;
	padding-bottom: 3rem;

	h2 {
		text-align: center;
		margin-bottom: 2rem;
		font-size: 2.25rem;
		color: ${(props) => props.accentColor};
	}
`;

const StyledGalleryGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	align-items: center;
	justify-content: center;
	gap: 30px;

	@media (max-width: 869px) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
	}
`;

export default Camera;
