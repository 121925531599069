import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Besaferate = () => {
	const { t } = useTranslation();
	return (
		<StyledSection>
			<div className="content">
				<img
					alt="besaferate logo"
					src="https://www.besaferate.com//wp-content/uploads/2019/01/logo-besaferate-colore-2.png"
				/>
				<h1>{t("besaferate.title1")}</h1>
				<p>{t("besaferate.caption1")}</p>
			</div>
			<div className="content--secondary">
				<h2>{t("besaferate.title2")}</h2>
				<p>{t("besaferate.caption2")}</p>
			</div>
			<div className="gridLayout">
				<div>
					<h3>{t("besaferate.features.rimborso.titolo")}</h3>
					<p>{t("besaferate.features.rimborso.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.garanzia.titolo")}</h3>
					<p>{t("besaferate.features.garanzia.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.assistenza.titolo")}</h3>
					<p>{t("besaferate.features.assistenza.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.anticipo.titolo")}</h3>
					<p>{t("besaferate.features.anticipo.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.assistenzalegale.titolo")}</h3>
					<p>{t("besaferate.features.assistenzalegale.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.rimborso2.titolo")}</h3>
					<p>{t("besaferate.features.rimborso2.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.assistenza2.titolo")}</h3>
					<p>{t("besaferate.features.assistenza2.contenuto")}</p>
				</div>
				<div>
					<h3>{t("besaferate.features.copertura.titolo")}</h3>
					<p>{t("besaferate.features.copertura.contenuto")}</p>
				</div>
			</div>
			<p className="polizza">
				{t("besaferate.polizza-text")}{" "}
				<a href="https://besafesuite.com/insurance/besaferate-es/">
					{t("besaferate.polizza-link")}
				</a>
			</p>
		</StyledSection>
	);
};

const StyledSection = styled.section`
	min-height: 100vh;
	display: grid;
	margin-top: 3em;
	padding: 0 2em;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr 1fr;
	justify-content: center;
	align-items: center;
	column-gap: 3em;

	p {
		margin-top: 1em;
	}

	h1 {
		font-size: 2.2em;
	}

	h2 {
		font-size: 1.9em;
	}

	.content {
		grid-column: 1/2;
		grid-row: 1/2;

		img {
			width: 35%;
		}
	}

	.contet--secondary {
		grid-column: 1/2;
		grid-row: 2/3;
	}

	.gridLayout {
		grid-column: 2/3;
		grid-row: 1/-1;
		padding: 1em;
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 1.5em;
		row-gap: 1.5em;

		div {
			text-align: center;
			padding: 2em;
			border-radius: var(--border-radius);
			background-color: var(--color-blu);
			color: white;

			h3 {
				font-size: 1.3em;
				text-transform: capitalize;
			}

			p {
				font-size: 1.1em;
			}
		}

		@media (max-width: 900px) {
			& {
				grid-template-columns: 1fr;
				grid-template-rows: unset;
			}
		}
	}
	.polizza {
		grid-column: 1/-1;
		text-align: center;
		align-self: start;
	}

	@media (max-width: 900px) {
		& {
			grid-template-columns: 1fr;
			grid-template-rows: unset;
			row-gap: 2em;
			margin-top: 10em;
		}
		.content,
		.content--secondary,
		.gridLayout {
			grid-column: 1/2;
			grid-row: auto;
		}
	}
`;

export default Besaferate;
