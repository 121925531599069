import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown.jsx";
import Toggle from "./Toggle";
import { useTranslation } from "react-i18next";

const Navbar = ({ drawClickHandler }) => {
	const { t, i18n } = useTranslation();
	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
	};
	return (
		<StyledNavbar>
			<div>
				<Toggle click={drawClickHandler} />
				<Link to="/">
					<StyledLogo src="/imgs/logo.png" alt="Logo" />
				</Link>
			</div>
			<StyledUl>
				<li>
					<StyledLink to="/">{t("navbar.home")}</StyledLink>
				</li>
				<li>
					<Dropdown />
				</li>
				<li>
					<StyledLink to="/viverePraiano">
						{t("navbar.viverePraiano")}
					</StyledLink>
				</li>
				<li>
					<StyledLink to="/info">{t("navbar.daSapere")}</StyledLink>
				</li>
				<li>
					<StyledLink to="/besaferate">BeSafe Rate</StyledLink>
				</li>
			</StyledUl>
			<div>
				<StyledFlagButton onClick={() => changeLanguage("it")}>
					<img src="/imgs/it.png" alt="Bandiera Italiana" />
				</StyledFlagButton>
				<StyledFlagButton onClick={() => changeLanguage("en")}>
					<img src="/imgs/uk.svg" alt="Bandiera Italiana" />
				</StyledFlagButton>
				<StyledCatFill href="https://booking.ericsoft.com/BookingEngine/Book?idh=FB9C455E247A8019">
					{t("bottoni.prenotaOra")}
				</StyledCatFill>
			</div>
		</StyledNavbar>
	);
};

const StyledNavbar = styled.nav`
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: white;
	color: var(--color-secondary);
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 1.4rem;
	padding-right: 1.4rem;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;

	div {
		display: flex;
		align-items: center;
		gap: 28px;
	}
`;

const StyledUl = styled.ul`
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style: none;

	li {
		text-align: center;
		margin-left: 7px;
		margin-right: 7px;
		text-transform: uppercase;
		font-size: 0.95rem;
	}

	@media (max-width: 1100px) {
		display: none;
	}
`;

const StyledCatFill = styled.a`
	padding: 0.5rem 1rem;
	color: white !important;
	font-size: 1.12rem;
	text-align: center;
	text-decoration: none;
	background-color: var(--color-red);
	border-radius: var(--border-radius);
	&:hover {
		text-decoration: none;
	}
`;

const StyledLogo = styled.img`
	width: 180px;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	font-weight: bold;
`;

const StyledFlagButton = styled.button`
	background: none;
	border: none;
	width: 1.2rem;
	cursor: pointer;
	@media (max-width: 1100px) {
		display: none;
	}
`;

/*,*/
export default Navbar;
