import React, {useEffect} from 'react'
import styled from "styled-components"
import {useTranslation} from "react-i18next"
import Map from "../components/Map.jsx" 
const DaSapere = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t } = useTranslation();

  const location = {
    address: "Via Gennaro Capriglione, 123, 84010 Praiano SA",
    lat: 40.61324812422141,
    lng: 14.522257454092808,
  }

  return (
    <StyledSection id="header">
      <div className="container">
      <StyledContainer>
        <StyledInfoSection className="infoSection">
          <h1>{t("daSapere.title")}</h1>
          <p>{t("daSapere.check")}</p>
          <p>{t("daSapere.colazione")}</p>
          <p>{t("daSapere.parcheggio")}</p>
          <StyledContactContainer>
            <div>
              <p><a href="mailto:info@stellamarinaamalficoast.com">info@stellamarinaamalficoast.com</a></p>
              <p><a href="tel:+393333969023"></a>Tel: +39 333 3969 023</p>
            </div>
            <div>
              <p>{t('daSapere.pet')}</p>
              <p>{t('daSapere.smoke')}</p>
              <p>{t('daSapere.double')}</p>
            </div>
          </StyledContactContainer>
        </StyledInfoSection>
        <StyledMapContainer className="mapSection">
          <Map location={location} />
        </StyledMapContainer>
      </StyledContainer>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  min-height: 100vh;
  display: grid;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.61) -5.45%, #44C3E5 59.91%, #021979 100%);

  @media (max-width: 900px) {
    padding-top: 10rem;
    padding-bottom: 10rem;
  };
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
  min-height: calc(100vh / 2 );
  width: calc(70% - 15px);
  margin: 0 auto;
  align-items: center;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: 1680px) {
    width: calc(80% - 15px);
  };

  @media (max-width: 1400px) {
    width: 100%;
  };

  @media (max-width: 900px) {
    
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  };
`
const StyledInfoSection = styled.div`
  padding:  2rem 0 2rem 2rem;

  h1 {
    font-size: 2.6rem;
    margin-bottom: 1.2rem;
    color: var(--color-red);
  };

  p {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    
    &:last-child {
      margin-bottom: 0;
    }
  };

  @media (max-width: 900px) {
    text-align: center; 
  };
`
const StyledContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  p {
    font-size: 1rem;
    text-align: center;
  };

  a {
    text-decoration: none;
    color: var(--color-red);
  };

  @media (max-width: 1400px) {
    flex-direction: column;
    gap: 1rem;
  };
`

const StyledMapContainer = styled.div`
  border-radius: var(--border-radius);
  height: 100%;
`


export default DaSapere;
